//
// Home features
//

// Choose your overall home feature cards layout
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  )
}

// Choose your card style for home feature cards
.homeFeatures .homeFeature {
  @include card;
  @include card-basic;
}

// Alternatively, you can target specific features rather than styling them all
// If you do this, you will need to target them ALL uniquely, so:
// .homeBox1 {...}, .homeBox2 {...}, .homeBox3 {...} etc.


//
// Home feeds
//

// Choose your overall home feeds layout
@include home-feeds-prefab (
  $number-of-feeds: 2,
  $prefab: 1
);

// Specify a card layout for each individual feed
.homeFeedBox1 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 2
  );
}

.homeFeedBox2 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 3
  );
}

// Choose your card style for home feed cards
.feedItem {
  @include card;
  @include card-basic;
}


//
// Listed posts
//

// Choose your card style for listed post cards
// (not products, search result or embedded map item)
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card;
  @include card-basic;
}


//
// Listed products
//

// If these are exactly the same as your regular 'listed posts' above, you can remove the ':not(.listedProduct)' selector above and squash this together into more efficient CSS output
.listedProduct {
  @include card;
  @include card-basic;
}





// Subsite Prefabs
// Heroes subsite
body.subsite.heroes {

  // Choose your overall home feeds layout
  @include home-feeds-prefab (
    $number-of-feeds: 12,
    $prefab: 1
  );

  // Specify a card layout for each individual feed
  .subsiteFeedBox1 .feedList {
    @include cards-layout-prefab (
      $number-of-cards: 3,
      $prefab: 1
    );
    .feedItem {
      @include card (
        $card-details-background-colour: #e30613,
        $card-hover-details-background-colour: #e30613,
        $card-text-align: center,
        $card-heading-font-size: $font-size-h1
      );
      @include card-basic;
      .feedItemDetailsWrapper {
        color: $white;
        max-width: max-content;
        margin: 0 auto;
      }
    }
  }

  .subsiteFeedBox2 .feedList {
    @include cards-layout-prefab (
      $number-of-cards: 3,
      $prefab: 2
    );
    .feedItem {
      @include card (
        $card-heading-colour: #3c424f,
        $card-hover-heading-colour: #3c424f,
        $card-heading-font-size: $font-size-h3,
        $card-hover-image-scale: 1,
        $card-hover-image-opacity: 1,
        $card-details-padding: $spacer * 3.5,
        $card-text-align: right,
        $card-details-background-colour: transparent,
        $card-hover-details-background-colour: transparent,
      );
      @include card-side-by-side (
        $card-side-by-side-image-position: right,
        $card-side-by-side-vertical-text-position: center,
        $card-side-by-side-breakpoint: map-get($breakpoints, md),
      );
    }
  }

  .subsiteFeedBox3 .feedList {
    @include cards-layout-prefab (
      $number-of-cards: 3,
      $prefab: 1
    );
    .feedItem {
      @include card (
        $card-image-background-colour: transparent,
        $card-text-align: center,
        $card-border-radius: 20px,
        $card-image-padding: 1rem 6rem 6rem 6rem,
        $card-image-border-radius: 400px,
        $card-hover-image-scale: 1,
        $card-hover-image-opacity: 1,
        $card-details-padding: $spacer,
        $card-heading-colour: #3c424f,
        $card-hover-heading-colour: #3c424f,
        $card-gap-width: 4rem,
        $card-details-background-colour: transparent,
        $card-hover-details-background-colour: transparent,
      );
      @include card-basic;
      .feedItemDetailsWrapper { margin-top: -6rem; }
    }
  }

  .subsiteFeedBox4 .feedList {
    @include cards-layout-prefab (
      $number-of-cards: 3,
      $prefab: 1
    );
    .feedItem {
      @include card (
        $card-heading-colour: #3c424f,
        $card-hover-heading-colour: #3c424f,
        $card-heading-font-size: $font-size-h3,
        $card-hover-image-scale: 1,
        $card-hover-image-opacity: 1,
        $card-details-padding: $spacer * 3.5,
      );
      @include card-side-by-side (
        $card-side-by-side-image-position: left,
        $card-side-by-side-vertical-text-position: center,
        $card-side-by-side-breakpoint: map-get($breakpoints, md),
      );
    }
  }

  .subsiteFeedBox5 .feedList {
    @include cards-layout-prefab (
      $number-of-cards: 3,
      $prefab: 1
    );
    .feedItem {
      @include card (
        $card-text-align: center
      );
      @include card-basic;
    }
  }

  .subsiteFeedBox6 .feedList {
    @include cards-layout-prefab (
      $number-of-cards: 4,
      $prefab: 2
    );
    .feedItem {
      @include card (
        $card-hover-image-scale: 1,
        $card-hover-image-opacity: 1,
        $card-details-padding: $spacer * 2,
        $card-image-background-colour: transparent,
        $card-text-align: center,
        $card-border-radius: 20px,
        $card-image-padding: 1rem 12rem 12rem 12rem,
        $card-border: 2px solid #F0F0F0,
        $card-hover-border-colour: #e30613,
        $card-image-border-radius: 400px,
        $card-heading-colour: #3c424f,
        $card-hover-heading-colour: #3c424f,
        // $card-gap-width: 2rem

      );
      @include card-basic;
      .feedItemDetailsWrapper { margin-top: -12rem; }
    }
  }

  .subsiteFeedBox7 .feedList {
    @include cards-layout-prefab (
      $number-of-cards: 3,
      $prefab: 1
    );
    .feedItem {
      @include card (
        $card-text-align: center,
        $card-details-background-colour: transparent,
        $card-hover-details-background-colour: transparent,
      );
      @include card-basic;
    }
  }

  .subsiteFeedBox8 .feedList {
    @include cards-layout-prefab (
      $number-of-cards: 3,
      $prefab: 1
    );
    .feedItem {
      @include card (
        $card-details-background-colour: transparent,
        $card-hover-details-background-colour: transparent,
      );
      @include card-basic;
      img {
        display: inline-block;
        max-height: 238px;
        margin: 0 5px;
        @media (max-width: 900px) {
          width: 100%;
          display: block;
          min-height: none;
          margin: 15px 0;
        }
      }
    }
  }

  .subsiteFeedBox9 .feedList {
    @include cards-layout-prefab (
      $number-of-cards: 3,
      $prefab: 1
    );
    .feedItem {
      @include card (
        $card-heading-colour: #3c424f,
        $card-hover-heading-colour: #3c424f,
        $card-heading-font-size: $font-size-h3,
        $card-hover-image-scale: 1,
        $card-hover-image-opacity: 1,
        $card-details-padding: $spacer * 3.5,
        $card-details-background-colour: transparent,
        $card-hover-details-background-colour: transparent,
      );
      @include card-side-by-side (
        $card-side-by-side-image-position: left,
        $card-side-by-side-vertical-text-position: center,
        $card-side-by-side-breakpoint: map-get($breakpoints, md),
      );
    }
  }

  .subsiteFeedBox10 .feedList {
    @include cards-layout-prefab (
      $number-of-cards: 3,
      $prefab: 1
    );
    .feedItem {
      @include card (
        $card-heading-colour: #3c424f,
        $card-hover-heading-colour: #3c424f,
        $card-heading-font-size: $font-size-h2,
        $card-hover-image-scale: 1,
        $card-hover-image-opacity: 1,
        $card-details-padding: $spacer * 3.5,
        $card-text-align: right
      );
      @include card-side-by-side (
        $card-side-by-side-image-position: right,
        $card-side-by-side-vertical-text-position: center,
        $card-side-by-side-breakpoint: map-get($breakpoints, md),
      );
    }
  }

  .subsiteFeedBox11 .feedList {
    @include cards-layout-prefab (
      $number-of-cards: 3,
      $prefab: 1
    );
    .feedItem {
      @include card (
        $card-image-background-colour: transparent,
        $card-text-align: center,
        $card-border-radius: 20px,
        $card-image-padding: 1rem 6rem 6rem 6rem,
        $card-border: 2px solid #F0F0F0,
        $card-hover-border-colour: #e30613,
        $card-image-border-radius: 400px,
        $card-hover-image-scale: 1,
        $card-hover-image-opacity: 1,
        $card-details-padding: $spacer,
        $card-heading-colour: #3c424f,
        $card-hover-heading-colour: #3c424f,
      );
      @include card-basic;
      .feedItemDetailsWrapper { margin-top: -6rem; }
    }
  }

  .subsiteFeedBox12 .feedList {
    @include cards-layout-prefab (
      $number-of-cards: 3,
      $prefab: 1
    );
    .feedItem {
      @include card (
        $card-heading-colour: #3c424f,
        $card-hover-heading-colour: #3c424f,
        $card-heading-font-size: $font-size-h3,
        $card-hover-image-scale: 1,
        $card-hover-image-opacity: 1,
        $card-details-padding: $spacer * 3.5,
        $card-text-align: right,
        $card-details-background-colour: transparent,
        $card-hover-details-background-colour: transparent,
      );
      @include card-side-by-side (
        $card-side-by-side-image-position: right,
        $card-side-by-side-vertical-text-position: center,
        $card-side-by-side-breakpoint: map-get($breakpoints, md),
      );
    }
  }

}
