// Change colour for <strong> in home intro
.homeIntro strong {
  color: $brand-primary;
}

// Add flourish lines
.homeIntro {
  @include heading-underline(
    $colour: $brand-primary,
    $justify: center,
    $width: 3em,
    $height: 3px
  );
}
.postContent h2 {
  @include heading-underline(
    $colour: $brand-primary,
    $width: 3em,
    $height: 3px
  );
}

.feedsTitle {
  @include heading-underline(
    $colour: $brand-primary,
    $width: 3em,
    $height: 3px
  );
}

// Footer stuff
.footerBox.footerBox2 {
  .footerLinks {
    display: flex;
    li a {
      font-weight: bold;
      padding: $spacer / 2;
      display: inline-block;
    }
  }
}

.footerBox .socialIcons {
  margin-bottom: $spacer;
}

.footerBox.footerBox4 {
  // grid-column: 1 / span 16;
  // background-color: white;
  margin: $spacer * 2 0 0 0;
  padding-bottom: $spacer * 2;
  display: flex;
  justify-content: center;
  padding: $spacer calc((100vw - 1240px) / 2);
  border-top: 1px solid rgba($grey-dark, 0.2);
  img {
    height: 60px;
    width: auto;
    margin: $spacer $spacer * 2;
  }
  @media (max-width: map-get($breakpoints, xl)) {
    flex-wrap: wrap;
  }
  @media (max-width: map-get($breakpoints, sm)) {
    display: block;
      img {
        display: block;
        margin: $spacer auto;
      }
  }
}


// Home feeds padding
.homeFeed:first-child {
  padding-top: $spacer * 5;
}

// Top level nav border-radius
@media (min-width: 1025px) {
  .menuMain .topLevel li.level1 > a {
    border-radius: 5px 5px 0 0;
  }
}

// Search button shadow
.header-search button {
  box-shadow: inset -1px -3px 0px rgba(0,0,0,0.2);
}

// Round corners on list/feature/feed images
.home-feed-image-link,
.home-feature-image-link,
.listed-post-image-link {
  border-radius: 5px;
}

// Adjust padding on Impact stats
.homefeaturecategory-homeboximpactstats {
  padding-top: $spacer * 3;
}

// Round corners on donation amounts form
.quickGivingPanel .formQuestion.donationAmount {
  border-radius: 5px;
}

// Custom colour for extra button in header
.mainCallToAction .cta-button.joinus {
  background-color: $_blue;
  &:hover {
    background-color: darken($_blue, 10%);
  }
}

// Mob fixes
@media (max-width: map-get($breakpoints, lg)) {
  .menuAdminContainer {
    top: -1.5rem;
  }
}

// Hide progress-meter
.progress-meter {
  display: none;
}

// Fix vertical alignment
.associatedStartDate time {
  vertical-align: middle;
}

// Min width for nav submenus
@media (min-width: 1025px) {
  .subMenu {
    min-width: 100%;
  }
}

// Heroes subsite
body.subsite.heroes {

  // Fonts
  h1, h2, h3, h4, h5, h6, a {
    font-family: 'Bangers', cursive !important;
  }

  // Header tweaks
  .headerContent { margin-top: 1rem; }

  // Subiste logo
  a.subsiteLogo {
    font-size: 0.75rem;
    color: #3c424f;
    text-decoration: none;
    &:hover { color: #e30613; }
    @media (max-width: 768px) {
      font-size: 0.5rem;
    }
  }

  // Hide unnecessary
  a.mainLogo,
  .headerTextSubsite { display: none; }

  // MenuSub
  .menuMain {
    @media (min-width: 1025px) {
      margin-top: -3.75rem;
      margin-bottom: 1rem;
      background-color: transparent;
    }
    ul.topLevel { justify-content: flex-end; }
    a { color: #3c424f; }
    a:hover {
      background-color: transparent;
      color: #e30613;
    }
  }

  // Carousel
  .carousel {
    max-width: none;
    padding: 0;
  }
  // Carousel gradient
  .carouselSlideTitle:after {
    background: rgba($black, 0.65);
  }

  // Carousel Details
  &.homepage .subsiteBody {
    position: relative;
    @media (min-width: 1025px) {
      margin-top: -20rem;
      color: $white;
    }
    > * { max-width: 700px; }
  }

  // Feed Titles
  .feedsTitle { text-align: center; }
  .feedsTitle:after { display: none; }

  // Home Feeds
  .homeFeed:first-child { padding-top: 0; }
  .homeFeed {
    padding-top: 2rem;
    padding-bottom: 2rem;
    &.subsiteFeedBox2 { background-color: #FAFAFA; }
    &.subsiteFeedBox3, &.subsiteFeedBox7, &.subsiteFeedBox8, &.subsiteFeedBox9,
    &.subsiteFeedBox12 { background-color: #F0F0F0; }
  }

  // Footer
  .pageFooterWrapper {
    background-color: $white;
    .pageFooter { padding: 1rem 0; }
    .pageFooter *:not(.footerBox3):not(.heroes) { display: none; }
      }

}
