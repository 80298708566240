//
// Environment
//


//
// Config values
//

$platform-name: 'haemochromatosisukthehaemochro';


//
// Colours
//

$_blue: #008FC3;

$brand-primary: #e30613;
$brand-secondary: #008A64;

$donate-colour: $brand-secondary;

// Create greyscale
$black:             #000;
$grey-dark:         #292b2c;
$grey:              #464a4c;
$grey-light:        #636c72;
$grey-lighter:      #eceeef;
$grey-lightest:     #f7f7f9;
$white:             #fff;





//
// Layout
//

$container-max-width: 1230px;
$post-content-width: 980px;


// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
);


// Spacers
$spacer: 1rem;


//
// Misc
//

$date-ordinals-enabled: false; // Turn off 'st', 'nd', 'rd' from dates


//
// Transitions
//



//
// Logo
//

$logo-width: 300px;
$logo-height: 80px;


//
// Typography
//

$font-family-base: 'Public Sans', Arial, sans-serif;
$font-size-base: 1.125rem;

$font-weight-normal: normal;
$font-weight-bold: bold;

$font-size-h1: 2.5em;
$font-size-h2: 2em;
$font-size-h3: 1.75em;
$font-size-h4: 1.5em;
$font-size-h5: 1.25em;
$font-size-h6: 1em;

$headings-margin-top: 1.5em; // This will only affect headings within post content


// Links

// Icon font


//
// Buttons
//

$btn-padding-x: 1.5em;
$btn-padding-y: .6em;
$btn-font-weight: $font-weight-bold;
$btn-border-radius: 100px;
$btn-box-shadow: inset -1px -3px 0px rgba(0,0,0,0.2);

// Donate button

//
// Social icons
// Documentation: https://fresco-docs.netlify.com/#/header/social-icons/
//

// Social network colours - use these by changing $social-icons-use-brand-colours to true
// $social-icons-background-colour: transparent; // A colour, transparent, or 'brand'
$social-icons-colour: white; // A colour, or 'brand'
$social-icons-hover-colour: rgba(white,0.5); // A colour, or 'brand'
// $social-icons-gap: 0;

// Header specific

// Footer specific social icons
$social-icons-footer-colour: white; // A colour, or 'brand'


//
// Share this page
//



//
// Form elements
//

$input-padding-y: .375rem;
$input-padding-x: .75rem;


// Field groups


//
// Tables
//



//
// Card
//

$card-border: 0;
$card-heading-font-size: $font-size-h4;
$card-footer-background-colour: transparent;
$card-image-border-radius: 5px;

// Card text overlay

// Card text over

// Card side by side

// Card hover state
$card-hover-image-opacity: .7;
$card-hover-image-scale: 1.1;


//
// Menu admin
//
$menu-admin-enabled: false;


//
// Header
//


// Header content
$header-content-margin-top: $spacer * 2;

// Tagline

// Main call to action

// Search

// Search - input
$header-search-input-margin-right: -39px;
$header-search-input-padding: $input-padding-y 43px $input-padding-y $input-padding-x;
$header-search-input-border-radius: 100px;

// Search - button
$header-search-button-border-radius: 100px;

// Social icons

// Colours set above (search for '$social-icons-header')


//
// Sticky header
//


//
// Navigation
//


// Top level items
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-hover-background-colour: $grey-light;
$nav-top-level-item-hover-colour: white;
// $nav-top-level-item-font-size: 1.125em;
$nav-top-level-item-padding: 10px 15px;

// Submenus
$nav-submenu-background-colour: $grey-light;
$nav-submenu-box-shadow: 0 20px 20px rgba(black, 0.05);
$nav-submenu-item-colour: white;
$nav-submenu-item-hover-colour: rgba(white, 0.8);
$nav-submenu-border-radius: 5px;
// $nav-submenu-item-font-size: 1.125em;

// Burger button

// Nav normal
$nav-normal-margin-bottom: 0;
$nav-normal-align-items: center; // left, center, right
// $nav-normal-max-width: 100%; // $container-max-width
$nav-normal-border-radius: 5px 5px 0 0;
$nav-normal-mobile-background-colour: $grey-lightest;


//
// Specifically when no banner present and you have gone for $header-absolute: true
//



//
// Carousel
//
// $carousel-max-width: 100%;
$carousel-image-overlay: linear-gradient(69.67deg, rgba(0, 0, 0, 0.6) 23.21%, rgba(0, 0, 0, 0.4) 46.48%, rgba(0, 0, 0, 0) 64.28%); // Add a colour value or gradient here
$carousel-details-background-colour: transparent;
$carousel-details-max-width: 500px;
$carousel-details-padding: 20px;
$carousel-details-position-y: center; // top, center, bottom
$carousel-contents-max-width: $container-max-width; // How wide do you want .carouselDetailWrapper?
$carousel-heading-colour: white;
$carousel-summary-colour: white;
$carousel-border-radius: 0 0 5px 5px;


// Carousel controls (left-right buttons)
$carousel-controls-position-y: center; // top, center, bottom
$carousel-controls-position-x: split; // split, left or right
$carousel-controls-button-background-colour: transparent;
$carousel-controls-icon-font-size: 1.5em;

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Carousel buttons dots

// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button

// Carousel after breakpoint
$carousel-mobile-details-background-colour: $grey-lighter;

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios

//
// Home intro
//
$home-intro-background-colour: white;
$home-intro-margin-top: 0;
$home-intro-padding-y: $spacer * 3;



//
// Home features
//
$home-features-padding-top: 0;
$home-features-padding-bottom: $spacer * 3;



//
// Impact stats
//
$impact-stats-padding-y: $spacer * 2;
$impact-stats-background-colour: $grey-lighter;
$impact-stats-max-width: $container-max-width;
$impact-stats-border-radius: 5px;


// Heading
$impact-stats-heading-margin-bottom: $spacer;

// Individual stat

// Figure
$impact-stats-figure-colour: $brand-primary;

// Summary


//
// Home feeds
//
$home-feeds-padding-top: 0;
$home-feeds-padding-bottom: $spacer * 3;


// Feeds title

// Feed item

// Event feed items
$feed-item-event-location-enabled: false;

// Twitter feed


//
// Footer
//

$footer-background-colour: $grey-lighter;
$footer-prefab: 6;
$footer-text-align: center;
$footer-link-decoration: none;
$footer-link-colour: $brand-primary;
$footer-link-hover-colour: rgba($footer-link-colour, .5);
$footer-font-size: 1rem;

// Footer admin links

// Newsletter

// Newsletter hero - full width form above rest of footer


//
// Context container
//
$context-container-border-radius: 5px;


// Donors list


//
// Posts
//

$post-content-max-width: $post-content-width;


//
// Header text (container for breadcrumb and page title)
//

$header-text-margin-top: $spacer;


//
// Page title
//

$page-title-margin-top: $spacer * 3;


//
// Sidebar
//

$sidebar-enabled: false; // Sidebars will always exist on event and appeal posts

// Associated lists

//
// Listing
//


// Listed post settings


//
// Breadcrumb
//



//
// Blockquote
//
$blockquote-border-radius: 5px;
$blockquote-background-colour: $grey-lighter;



//
// Fundraising
//


// Find a fundraiser widget

// Top fundraisers


//
// Donation form
//
$donation-form-banner-enabled: false;


// Donation amounts


//
// Quick giving panel
//
$quick-giving-background-colour: $grey-lighter;



//
// Home quick giving panel
//


// Adjacent homepage quick giving

// Overlay homepage quick giving


//
// Totalisers
//



//
// Comments
//



//
// Blog details
//



//
// FAQs
//



//
// Shop
//

$shop-enabled: true; // Quickly disable shop on the platform (hide basket and not include relevant Sass for compilation)

// Departments list

// Product-price

// Product post


// Subsite
$subsite-enabled: true;
$subsite-hide-main-header: false;
$subsite-hide-main-tagline: true;
$subsite-hide-header-search: true;
$subsite-hide-main-cta: true;
$subsite-content-max-width: $container-max-width;

// Subsite logo
$subsite-logo: "title";
$subsite-logo-width: 120px;
$subsite-logo-height: 120px;
$subsite-logo-margin: 1rem 0;

// Subsite nav
$subsite-nav-breakpoint: map-get($breakpoints, lg);
$subsite-nav-max-width: 1230px;
$subsite-nav-margin-top: -60px;
$subsite-nav-margin-bottom: 1rem;
$subsite-nav-background-colour: transparent;
$subsite-nav-contents-max-width: 100%;
$subsite-nav-align-items: right;
$subsite-nav-top-level-item-padding: $nav-top-level-item-padding;
$subsite-nav-top-level-item-colour: $black;
$subsite-nav-top-level-item-font-family: "Bangers", cursive;
$subsite-nav-top-level-item-font-size: 1rem;
$subsite-nav-top-level-item-font-weight: $nav-top-level-item-font-weight;
$subsite-nav-top-level-item-background-colour: transparent;
$subsite-nav-top-level-item-hover-colour: #e30613;
$subsite-nav-top-level-item-hover-background-colour: transparent;

// $subsite-nav-submenu-background-colour: $nav-submenu-background-colour;
// $subsite-nav-submenu-box-shadow: $nav-submenu-box-shadow;
// $subsite-nav-submenu-border-radius: $nav-submenu-border-radius;
// $subsite-nav-submenu-item-padding: $nav-submenu-item-padding;
// $subsite-nav-submenu-item-colour: $nav-submenu-item-colour;
// $subsite-nav-submenu-item-font-family: $nav-submenu-item-font-family;
// $subsite-nav-submenu-item-font-size: $nav-submenu-item-font-size;
// $subsite-nav-submenu-item-font-weight: $nav-submenu-item-font-weight;
// $subsite-nav-submenu-item-hover-colour: $nav-submenu-item-hover-colour;
// $subsite-nav-submenu-item-hover-background-colour: $nav-submenu-item-hover-background-colour;



// Subsite logo

// Subsite nav


//
// Cookie consent
//
